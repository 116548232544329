<template>
  <div class="editar-perfil">
    <Voltar />
    <h1 class="titulo">Editar perfil</h1>
    <p>
      <strong>ATENÇÃO:</strong>
      caso seja sorteado, o prêmio será entregue para
      o titular do <br>
      <span class="texto-destaque"> CPF: {{ user.cpf }} </span> cadastrado no
      aplicativo.
    </p>
   <form class="form" @submit.prevent="handleSubmit">
    <div class="bt">
      <a type="button"><span>Nome:</span></a>
        <input style="width: 90%" type="text" v-model='state.nome' />
    </div>
    <div class="bt">
      <a type="button"><span>Sobrenome:</span></a>
        <input style="width: 90%" type="text" v-model='state.sobrenome' />
    </div>
    <div class="bt">
      <a type="button"> <span>Celular:</span> </a>
      <input style="width: 90%" type="tel" v-model='state.celular' />
    </div>
    <div class="bt">
      <a type="button"> <span>Senha: </span> </a>
      <input style="width: 90%" type="password" v-model='state.senha' />
    </div>
    <div class="bt">
      <button type="submit" :disabled="state.isLoading">SALVAR</button>
    </div>
  </form>
  </div>
</template>
<script>
import { reactive } from 'vue'
import { useToast } from 'vue-toastification'
import services from '../../services'
import Voltar from '../../components/Voltar'

export default {
  setup () {
    const toast = useToast()
    const user = JSON.parse(window.localStorage.getItem('user'))

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      nome: user.nome,
      sobrenome: user.sobrenome,
      celular: user.celular,
      senha: ''
    })

    async function handleSubmit () {
      toast.clear()
      try {
        state.isLoading = true
        const { data, errors } = await services.editarperfil.index({
          nome: state.nome,
          sobrenome: state.sobrenome,
          celular: state.celular,
          senha: state.senha,
          token: user.token
        })

        if (!errors && !data.error_message) {
          user.nome = data.nome
          user.sobrenome = data.sobrenome
          user.celular = data.celular

          window.localStorage.setItem('user', JSON.stringify(user))
          toast.success('Obrigado! Dados alterados com sucesso!!')
        } else {
          toast.error('Não foi possível alterar seus dados! - 1')
        }

        state.isLoading = false
      } catch (error) {
        toast.error('Não foi possível alterar seus dados! - 2')
        console.log(error)
        state.isLoading = false
        state.hasErrors = !!error
      }
    }

    return {
      state,
      handleSubmit,
      user
    }
  },
  components: { Voltar }
}
</script>
